/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
require('./src/components/layouts/MainLayout/main.css');

module.exports = {
  onInitialClientRender: () => {
    /* eslint-disable */
    /* ADD ALL GLOBAL SCRIPTS HERE */

    // Segment, gtm, GA and heap
    !(function() {
      var analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked)
          window.console &&
            console.error &&
            console.error('Segment snippet included twice.');
        else {
          analytics.invoked = !0;
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
          ];
          analytics.factory = function(t) {
            return function() {
              var e = Array.prototype.slice.call(arguments);
              e.unshift(t);
              analytics.push(e);
              return analytics;
            };
          };
          for (var t = 0; t < analytics.methods.length; t++) {
            var e = analytics.methods[t];
            analytics[e] = analytics.factory(e);
          }
          analytics.load = function(t, e) {
            var n = document.createElement('script');
            n.type = 'text/javascript';
            n.async = !0;
            n.src =
              'https://cdn.segment.com/analytics.js/v1/' +
              t +
              '/analytics.min.js';
            var a = document.getElementsByTagName('script')[0];
            a.parentNode.insertBefore(n, a);
            analytics._loadOptions = e;
          };
          analytics.SNIPPET_VERSION = '4.1.0';
          analytics.load('jJkJlppRYoJKdvT3Goj3UEuPpBs8tQIx');
          analytics.page();

          const userId =
            window.location.search.match(/userId=(.*)/gi) &&
            window.location.search
              .match(/userId=(.*)/gi)[0]
              .replace(/userId=/gi, '');

          if (userId) {
            analytics.identify(userId);
          }
        }
    })();
    /* eslint-enable */
  },
};

const loadableReady = require('@loadable/component').loadableReady;
const ReactDOM = require('react-dom');

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback);
    });
  };
};
